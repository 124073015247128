import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Link as GatsbyLink } from 'gatsby';

import ImageProvider from '@core/providers/image/image-provider';

import Hero from '@shared/hero/hero';

import { Graphql } from '@models/graphql';

import './styles.scss';

type NotFoundProps = {
  language: string;
};

export default function NotFound({ language }: NotFoundProps) : React.ReactElement {
  const { t } = useTranslation();
  const node = {
    relationships: {
      image: {
        name: 'imagen-for-hero.png'
      }
    }
  } as Graphql;

  return (
    <div className="not-found--page">
      <div className="hero-container mb-5">
        <Hero node={node} />
      </div>
      <Container>
        <div className="d-flex flex-column align-items-center mb-4">
          <ImageProvider
            fileName="404.png"
            className="image-not-found mb-4"
            width="300px"
            height="300px"
          />
          <h2 className="py-2">
            ¡
            <span className="text-info">
              {`${t('WOW')} `}
            </span>
            {`${t('WE HAVE A PROBLEM')}!`}
          </h2>
          <h2 className="text-muted py-2">
            {t('Looks like something went wrong')}
          </h2>
          <GatsbyLink to={`/${language}`} className="text-dark-indigo font-weight-bold text-decoration-none py-2">
            {t('Go to home')}
          </GatsbyLink>
        </div>
      </Container>
    </div>
  );
}
